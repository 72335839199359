<template>
  <div id="demo-basic-card">
    <div class="vx-row pt-10">

      <!-- USER PROFILE CARD 2 - MINIMAL -->
      <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">

        <vx-card class="p-2 w-full">
          <router-link :to="{ name: 'group-management'}"
                       class="flex align-items-center justify-content-center flex-wrap p-5">
            <h2 class="mb-1 flex flex-100 justify-content-center">Learning Centre Groups</h2>
            <h3>{{ totalGroups }}</h3>
          </router-link>
        </vx-card>

      </div>
      <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
        <vx-card class="p-2 w-full">
          <router-link to="/admin/learning-centers"
                       class="flex align-items-center justify-content-center flex-wrap p-5">
            <h2 class="mb-1 flex flex-100 justify-content-center">Learning Centres</h2>
            <h3>{{ totalCentres }}</h3>
          </router-link>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {

  data() {
    return {
      totalGroups: 0,
      totalCentres: 0,
      totalTeacher: 0,
      totalChildren: 0,

      subscribersGained: {},
    };
  },
  methods: {
    ...mapActions("group", [
      "countGroup",
    ]),

    ...mapActions("center", [
      "countCenters",
    ]),
  },
  created() {
    this.$vs.loading()
    this.countGroup()
      .then((res) => {
        if (res.status === 200) {
          this.totalGroups = res.data.data;
        }
        this.$vs.loading.close()
      })
      .catch(err => {
        this.$router.push(`/admin/profile?tab=xero`);
      })
      .finally(() => {
        this.$vs.loading.close();
      })

    this.countCenters()
      .then((res) => {
        if (res.status === 200) {
          this.totalCentres = res.data.data;
        }
        this.$vs.loading.close()
      })
      .catch(err => {
        this.$vs.loading.close()
        console.error(err)
      });
  }

}
</script>
<style lang="scss" scoped>
.flex-100 {
  flex: 0 0 100%;
}
</style>
